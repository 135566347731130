.list-area {
  height: 100vh;
  background-color: #d1c4e9;
  overflow: auto;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  -webkit-font-smoothing: antialiased;
}
