.header {
  background-color: #673ab7;
  color: #fff;
  min-height: 40px;
  position: relative;
}

.title {
  padding-top: 4px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  font-weight: 900;
}

.header img {
  height: 20px;
  position: absolute;
  top: 10px;
  right: 20px;
}
