.list-area {
  height: 100vh;
  background-color: #d1c4e9;
  overflow: auto;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  -webkit-font-smoothing: antialiased;
}

.app-button {
  width: 180px;
}

.info-text {
  font-size: 15px;
  padding-left: 10px;
  width: 30ch;
  white-space: nowrap;
  overflow: hidden;
}

.info-view {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  background-color: #d1c4e9;
  min-height: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}

.icon-container {
  width: 40px;
}

.info-icon-normal {
  -webkit-animation: unset;
          animation: unset;
}

#info-icon {
  display: block;
  margin: auto;
  max-height: 40px;
  max-width: 40px;
  width: 40px;
  height: 40px;
}

.info-area {
  display: flex;
  flex-direction: row;
  background-color: #ede7f6;
  min-height: 40px;
  width: 80%;
  border: 1px dashed #673ab7;
  border-radius: 5px;
}

.listening {
  background-color: #90ee90;
}

.speech-not-supported {
  background-color: #ffc0cb;
}


ul {
  -webkit-padding-start: 0;
  font-size: 1.5em;
}

li {
  list-style: none;
}

input[type="checkbox"] {
  margin-right: 10px;
  transform: scale(1.5);
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
}

.items {
  padding-left: 20px;
  padding-right: 20px;
}

.item-collected {
  color: #b39ddb;
  text-decoration: line-through;
}

.item-normal {
  color: #311b92;
  text-decoration: none;
}

.item-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-transform: capitalize;
  -webkit-font-smoothing: antialiased;
}

.items-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
}

.bm-cross {
  background-color: white;
}

.bm-menu {
  background-color: #673ab7;
  padding: 10px;
}

.bm-burger-bars {
  background-color: white;
  border-radius: 5px;
}

.bm-item {
  outline-width: 0;
  color: white;
  font-size: 2rem;
}

hr {
  margin-top: 5px;
  margin-bottom: 5px;
}

input {
  margin-left: 2px;
}

.bm-burger-button {
  margin: 10px;
  position: absolute;
  width: 20px;
  height: 20px;
}

.item>* {
  font-size: 1.5rem;
}

.row {
  display: flex;
}

.row>div:first-child {
  width: 3rem;
}

.version {
  border-top: 1px solid #ffffff;
  margin-top: 5rem;
}

.header {
  background-color: #673ab7;
  color: #fff;
  min-height: 40px;
  position: relative;
}

.title {
  padding-top: 4px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  font-weight: 900;
}

.header img {
  height: 20px;
  position: absolute;
  top: 10px;
  right: 20px;
}

