.bm-cross {
  background-color: white;
}

.bm-menu {
  background-color: #673ab7;
  padding: 10px;
}

.bm-burger-bars {
  background-color: white;
  border-radius: 5px;
}

.bm-item {
  outline-width: 0;
  color: white;
  font-size: 2rem;
}

hr {
  margin-top: 5px;
  margin-bottom: 5px;
}

input {
  margin-left: 2px;
}

.bm-burger-button {
  margin: 10px;
  position: absolute;
  width: 20px;
  height: 20px;
}

.item>* {
  font-size: 1.5rem;
}

.row {
  display: flex;
}

.row>div:first-child {
  width: 3rem;
}

.version {
  border-top: 1px solid #ffffff;
  margin-top: 5rem;
}
